'use client'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import 'posthog-js/dist/surveys'
import { useEffect } from 'react'

export function PHProvider({
	children,
}: {
	children: React.ReactNode
}) {
	useEffect(() => {
		if (typeof window !== 'undefined' && process.env.NEXT_PUBLIC_POSTHOG_KEY) {
			posthog?.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
				api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
				capture_pageview: false, // Disable automatic pageview capture, as we capture manually
				person_profiles: 'always', // Enables capturing of person profiles
			})
		}
	}, [])

	return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}