'use client'
import React, { useEffect, useState } from 'react'
import { errorHandler } from '@/config/ErrorBoundary'
import { useClaimsResult } from '@/services/firebase/useClaimsResult'
import { SubmitHandler, useForm } from 'react-hook-form'
import useDatalayer from '@/hooks/useDatalayer'
import { FacebookAuthProvider, GoogleAuthProvider, OAuthProvider, UserCredential, onAuthStateChanged, signInWithCustomToken, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth'
import { auth, auth as firebaseAuth } from '@/config/DBConnection'
import { useRouter, useSearchParams } from 'next/navigation'
import { LogoSelector } from './LogoSelector'
import { FullLoader, Text, Button } from '@umahealth/occipital'
import { Input } from '@umahealth/occipital/client'
import Google from '@/assets/google.png'
import Microsoft from '@/assets/microsoft.png'
import Image from '@/reusableComponents/Image'
import './styles.css'
import { useUpdateTokens } from '../hooks/useUpdateTokens'
import { removeAuthCookies, saveUserInServer, setAuthServerToken } from '@/app/cookies/authCookies'
import { trackEventLoginWithEmail, trackEventLoginWithProvider, trackEventRedirectToSignup, trackEventRequireChangePassword } from '@/events/loginEvents'
import Status from '@/components/GeneralComponents/Problem/Status'
import { useSignInWithCustomToken } from '../hooks/useSignInWithCustomToken'
import { setLoginMethod } from '@/context/auth/authServerActions'

interface IFormLoginEmail {
	email: string,
	password: string
}

export function LoginWithEmail() {
	const router = useRouter()
	const claimsResultMutation = useClaimsResult()
	const {
		register,
		watch,
		formState: { errors },
		handleSubmit,
		setError
	} = useForm<IFormLoginEmail>()

	const params = useSearchParams()
	const [ hasLogged, setHasLogged ] = React.useState(false)
	const redirect = params?.get('redirect')
	const customToken = params?.get('customToken')
	const hasIomaToken = !!params?.get('token')
	const [ facebookNavigator, setFacebookNavigator ] = useState(false)

	/** Si existe autenticación del usuario, te actualiza la sesión en el back, sino te remueve todo lo necesario para que inicies sesión */
	const updateTokens = useUpdateTokens({
		options: {
			onSuccess: () => {
				router.push(redirect ?? '/')
			},
			onError: async () => {
				localStorage.clear()
				removeAuthCookies()
				signOut(firebaseAuth)
				window.location.replace('/login')
			}
		}
	})


	const signInWithCustomTokenMutation = useSignInWithCustomToken({
		onSuccess: () => {
			router.replace(redirect ?? '/')
		}
	})

	useEffect(() => {
		if (params?.get('verifyCache') === 'true') {
			const authStateChange = onAuthStateChanged(auth, (user) => {
				if (user) {
					updateTokens.mutate(user)
				}


			})
			return () => authStateChange()
		}
	}, [ auth ])

	useEffect(() => {
		/** Tiene que estar en un useEffect para no dar problemas con nextJs */
		if (navigator?.userAgent?.includes('FB_IAB')) {
			setFacebookNavigator(true)
		}
		setFacebookNavigator(false)

	}, [])


	React.useEffect(() => {
		if (customToken) {
			signInWithCustomTokenMutation.mutate({customToken, hasIomaToken})
		}
	}, [ firebaseAuth, customToken ])

	useDatalayer({
		'instance': process.env.NEXT_PUBLIC_DATALAYER_INSTANCE,
		'page_title': 'Login Email',
		'content_group': 'login',
		'event': 'custom_pageview',
		'page_location': `${process.env.NEXT_PUBLIC_PAGE_LOCATION}/login/email`
	})

	const handleSignInWithEmail: SubmitHandler<IFormLoginEmail> = async (formData) => {
		setHasLogged(true)
		let credentials
		try {
			credentials = await signInWithEmailAndPassword(firebaseAuth, formData.email, formData.password)
			await setLoginMethod("email_and_password")
			trackEventLoginWithEmail()
		} catch (err) {
			setHasLogged(false)
			setError('password', { type: 'custom', message: 'Email o contraseña no válida' })
			if (err instanceof Error) {
				if (window.dataLayer) {
					window.dataLayer.push({
						'instance': process.env.NEXT_PUBLIC_DATALAYER_INSTANCE, 'event': 'login_error',
						'error_message': `${err.message}`
					})
				}
				if (errorHandler) {
					errorHandler.report(err)

				}
			}
		}

		if (credentials) {
			if (window.dataLayer) {
				window.dataLayer.push({
					'instance': process.env.NEXT_PUBLIC_DATALAYER_INSTANCE,
					'event': 'login',
					'method': 'Email',
					'user_id': credentials?.user?.uid
				})
			}

			const idTokenResult = await credentials.user.getIdTokenResult()

			if (idTokenResult.token) {
				await saveUserInServer(idTokenResult)
				await setAuthServerToken(idTokenResult.token, idTokenResult.expirationTime)
			}

			const claimsresults = await claimsResultMutation.mutateAsync()
			if (claimsresults && claimsresults.requirePasswordChange) {
				router.push('/change-password')
			} else {
				router.push('/home')
			}
		}
	}

	async function handleLogin(name: 'Google' | 'Facebook' | 'Microsoft') {
		const provider = {
			Google: new GoogleAuthProvider(),
			Facebook: new FacebookAuthProvider(),
			Microsoft: new OAuthProvider('microsoft.com'),
		}
		try {
			setHasLogged(true)
			let credential: UserCredential
			if (customToken) {
				credential = await signInWithCustomToken(
					firebaseAuth,
					customToken,
				)
			} else {
				credential = await signInWithPopup(firebaseAuth, provider[ name ])
			}

			const idTokenResult = await credential?.user?.getIdTokenResult()

			if (idTokenResult?.token) {
				await saveUserInServer(idTokenResult)
				await setAuthServerToken(idTokenResult.token, idTokenResult.expirationTime)
				if(customToken) {
					await setLoginMethod("custom_token")
				}else {
					await setLoginMethod(name.toLocaleLowerCase())
				}
			}
			window.location.replace(redirect ?? '/')
		} catch (err) {
			setHasLogged(false)
			console.error(err)
			errorHandler?.report(`[ ${handleLogin.name} ] => Error trying to login with ${name} - ${JSON.stringify(err)}`)
		}
	}

	if (hasLogged === true) {
		return (
			<div>
				<FullLoader>Cargando información del usuario...</FullLoader>
			</div>
		)
	}

	if (signInWithCustomTokenMutation.isError) {
		return (
			<Status
				type='warning'
				title='El link de inicio de sesión ya expiró'
				iconName='serverError'
				showError={false}
				subtitle={`Error detallado: ${signInWithCustomTokenMutation.error?.message}`}
			>
				<Button
					type='button'
					variant='filled'
					onClick={() => signInWithCustomTokenMutation.reset()}
				>
					Ingresar nuevamente
				</Button>
			</Status>
		)
	}


	return (
		<>
			<LogoSelector />
			<Text className='px-9' size='text-l' color='text-primary' weight='font-semibold' tag='h2'>
				Inicia sesión
			</Text>
			<form className="p-9 flex flex-col gap-4" onSubmit={handleSubmit(handleSignInWithEmail)}>
				<Input
					inputMode='email'
					type='email'
					autoComplete='email'
					label='Correo'
					{...register('email')}
					error={errors.email?.message}
				/>
				<Input
					label="Contraseña"
					type="password"
					inputMode="text"
					{...register('password')}
					error={errors.password?.message}
				/>
				<div className="flex w-fit ml-auto -mt-5">
					<Button
						size='full'
						type='button'
						variant='text'
						action={() => {
							trackEventRequireChangePassword()
							router.push('/forgot')
						}}
					>
						<span className='text-xs text-primary text-right'>
							¿Olvidaste tu contraseña?
						</span>
					</Button>
				</div>
				<Button
					disabled={!watch('email')?.length && !watch('password')?.length}
					variant='filled'
					type='submit'
					size="full"
				>
					Confirmar
				</Button>
			</form>
			<div>
				<p className='text-center text-xs text-gray-500 description'>También podés ingresar con</p>
				<div className="flex gap-6 items-center justify-center my-5">
					{/* Sí, es raro. Pero google no confía en el browser de FB, no hay manera. :( */}
					{!facebookNavigator && (
						<button onClick={() => {
							trackEventLoginWithProvider('Google')
							handleLogin('Google')
						}}>
							<Image
								className="w-auto h-5"
								src={Google}
								alt={'Google icon'}
								width="18px"
								height="18px"
							/>
						</button>
					)}
					{/* Facebook esta dado de baja */}
					{/* <button onClick={() => {
						trackEventLoginWithProvider('Facebook')
						handleLogin('Facebook')
					}}>
						<Image
							className="w-auto h-5"
							src={Facebook}
							alt={'Facebook icon'}
							width="18px"
							height="18px"
						/>
					</button> */}
					<button onClick={() => {
						trackEventLoginWithProvider('Microsoft')
						handleLogin('Microsoft')
					}}>
						<Image
							className="w-auto h-5"
							src={Microsoft}
							alt={'Microsoft icon'}
							width="18px"
							height="18px"
						/>
					</button>
				</div>
			</div>
			<section className='flex items-center text-xs justify-center'>
				<span>
					¿No tienes cuenta?
				</span>
				<Button
					type='button'
					variant='text'
					onClick={() => {
						trackEventRedirectToSignup()
						router.push('/signup')
					}}
					className='text-xs w-[112px]'
				>
					Crear cuenta
				</Button>
			</section>
		</>
	)
}
